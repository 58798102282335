import { Card, CardContent, Dialog, DialogContent, Grid2 } from '@mui/material'
import Checkbox from 'components/form/Checkbox'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import { BasicDialogAction, BasicDialogTitle } from 'components/modal/DialogComponents'
import GDLAction from 'gdl/actions/GDLAction'
import { isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ToastActionConstant } from 'toastr/reducers/ToastrReducer'
import useBoolean from 'utils/customHook/useBoolean'

const TYPE_FEATURES = [
    { value: 'MODULE', label: 'Module' },
    { value: 'ECRAN', label: 'Ecran' },
    { value: 'ACTION', label: 'Action' },
]

const DialogAdminFeature = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
    adminFeature = {},
}) => {
    const dispatch = useDispatch()
    const {
        adminFeatures,
        login = '',
    } = useSelector(store => ({
        adminFeatures: store.GDLReducer.adminFeatures,
        login: store.AdminReducer.user?.login,
    }), shallowEqual)

    const [code, setCode] = useState()
    const [typeFeature, setTypeFeature] = useState()
    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const { value: pluggedInFrontend, setValue: setPluggedInFrontend, toggle: togglePluggedInFrontend } = useBoolean()

    useEffect(() => {
        if (!isOpen) return

        setCode(adminFeature.code)
        setTypeFeature(adminFeature.typeFeature)
        setName(adminFeature.name)
        setDescription(adminFeature.description)
        setPluggedInFrontend(adminFeature.pluggedInFrontend)
    }, [adminFeature.code, adminFeature.name, adminFeature.description, adminFeature.pluggedInFrontend, adminFeature.typeFeature, isOpen])

    useEffect(() => {
        const isDuplicate = adminFeatures.some(af => af.code === code)
        if (isDuplicate && adminFeature.code !== code) {
            dispatch(ToastActionConstant.warning('Le code existe déjà'))
        }
    }, [code])

    const onSave = () => {
        const newAdminFeature = {
            id: 0,
            creationDate: 0,
            updateDate: 0,
            updateLogin: login,
            parent: undefined,
            ...adminFeature,
            code,
            typeFeature,
            name,
            description,
            pluggedInFrontend,
        }
        const promise = isNil(adminFeature.id) ? dispatch(GDLAction.createAdminFeatures(newAdminFeature)) : dispatch(GDLAction.updateAdminFeatures(newAdminFeature))
        promise.then(() => {
            onValidate()
            onClose()
        })
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={'lg'}
        >
            <BasicDialogTitle onClose={onClose}>
                Paramètrage
            </BasicDialogTitle>
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Card>
                    <CardContent>
                        <Grid2 container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                            <Grid2 size={6}>
                                <Input
                                    label='Code'
                                    value={code}
                                    onChange={setCode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    label='Type de fonctionnalité'
                                    items={TYPE_FEATURES}
                                    value={typeFeature}
                                    onChange={setTypeFeature}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    label='Nom'
                                    value={name}
                                    onChange={setName}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Checkbox
                                    label='Branché sur Sieau'
                                    checked={pluggedInFrontend}
                                    onToggle={togglePluggedInFrontend}
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <Input
                                    label='Description'
                                    value={description}
                                    onChange={setDescription}
                                    multiline
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <BasicDialogAction
                buttonLabel={isNil(adminFeature.id) ? 'Créer' : 'Modifier'}
                onValidate={onSave}
                isDisable={!code || !typeFeature}
            />
        </Dialog>
    )
}

export default DialogAdminFeature