import React from 'react'
import { createRoot } from 'react-dom/client'
import { Link, Navigate, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ReduxRouter } from '@lagunovsky/redux-react-router'
import AppStore, { history } from 'store/AppStore'
import { ThemeProvider } from '@mui/material/styles'
import { Route } from 'react-router-dom'
import BoundaryError from 'components/BoundaryError'
import ToastrContainer from 'toastr/components/ToastrContainer'
import AppTheme from 'AppTheme'
import Wait from 'wait/components/Wait'
import './index.css'
import 'material-icons/iconfont/material-icons.css'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import LoginApp from './home/components/LoginApp'
import { redirectURI } from './utils/FormUtils'
import DiscordRedirectApp from './home/components/DiscordRedirectApp'
import DashboardApp from 'dashboard/components/DashboardApp'
import ServicesApp from 'service/components/ServicesApp'
import InstancesApp from 'instance/components/InstancesApp'
import TimeErrorsApp from 'timeError/components/TimeErrorsApp'
import UsersApp from 'admin/components/UsersApp'
import UserApp from 'admin/components/UserApp'
import AuthenticatedComponent from 'AuthenticatedComponent'
import AquaparcApp from 'AquaparcApp'
import InstanceApp from 'instance/components/InstanceApp'
import InstanceDasboardApp from 'instance/components/InstanceDashboardApp'
import InstanceDescriptionApp from 'instance/components/InstanceDescriptionApp'
import SettingLicenseApp from 'gdl/components/settingLicense/SettingLicenseApp'

if (window.location.href.includes('access_token')) {
    const matches = [...window.location.href.matchAll('access_token=([a-z0-9A-Z\\.]*)')]
    if (matches.length && matches[0].length && matches[0][1]) {
        if (window.location.href.includes('localhost:3002')) {
            redirectURI(`http://localhost:3002/#/discordRedirect?code=${matches[0][1]}`)
        } else {
            redirectURI(`https://aquaparc.aquasys.fr/#/discordRedirect?code=${matches[0][1]}`)
        }
    }
}

const NoMatch = () => (
    <div style={{ margin: 10 }}>
        <h2>Nothing to see here!</h2>
        <p>
            <Link to='/'>Go to the home page</Link>
        </p>
    </div>
)

const container = document.getElementById('app')
const root = createRoot(container)
root.render(
    <ThemeProvider theme={AppTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Provider store={AppStore}>
                <ReduxRouter history={history}>
                    <BoundaryError>
                        <ToastrContainer />
                        <Wait />
                        <Routes>
                            {/* <Route path='/slackRedirect' element={<PostSlackRedirectApp />} /> */}
                            <Route path='/discordRedirect' element={<DiscordRedirectApp />} />
                            <Route path='/login' element={<LoginApp />} />
                            <Route path='/' element={<AuthenticatedComponent Component={AquaparcApp} />}>
                                <Route index element={<DashboardApp />} />
                                <Route path='service' element={<ServicesApp />} />
                                <Route path='instance'>
                                    <Route index element={<InstancesApp />} />
                                    <Route path=':id' element={<InstanceApp />}>
                                        <Route index element={<Navigate replace path='' to='dashboard' />} />
                                        <Route path='dashboard' element={<InstanceDasboardApp />} />
                                        <Route path='description' element={<InstanceDescriptionApp />} />
                                    </Route>
                                </Route>
                                <Route path='timeError' element={<TimeErrorsApp />} />
                                <Route path='user' element={<UsersApp />} />
                                <Route path='user/:login' element={<UserApp />} />
                                <Route path='settingLicense' element={<SettingLicenseApp />} />
                                <Route path='*' element={<NoMatch />} />
                            </Route>
                        </Routes>
                    </BoundaryError>
                </ReduxRouter>
            </Provider>
        </LocalizationProvider>
    </ThemeProvider>,
)