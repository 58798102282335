import ApplicationConf from 'conf/ApplicationConf'
import { GDLActionConstant } from 'gdl/reducers/GDLReducer'
import { catchFunctionBuilder, genericPromise2, thenFunctionCreateBuilder, thenFunctionDeleteBuilder, thenFunctionUpdateBuilder } from 'utils/ActionUtils'

const GDLAction = {
    fetchAdminFeatures: () => dispatch => genericPromise2(ApplicationConf.gdl.adminFeatures())
        .then((list = []) => dispatch(GDLActionConstant.adminFeatures(list)))
        .catch(catchFunctionBuilder('Erreur lors de la récupération des fonctionnalitées', dispatch)),
    createAdminFeatures: adminFeatures => dispatch => genericPromise2(ApplicationConf.gdl.adminFeatures(), { body: adminFeatures, method: 'POST' })
        .then(thenFunctionCreateBuilder('Fonctionnalitée créée avec succès', dispatch))
        .catch(catchFunctionBuilder('Erreur lors de la création d\'une fonctionnalitée', dispatch)),
    updateAdminFeatures: adminFeatures => dispatch => genericPromise2(ApplicationConf.gdl.adminFeatures(), { body: adminFeatures, method: 'PUT' })
        .then(thenFunctionUpdateBuilder('Fonctionnalitée modifiée avec succès', dispatch))
        .catch(catchFunctionBuilder('Erreur lors de la modification d\'une fonctionnalitée', dispatch)),
    deleteAdminFeatures: id => dispatch => genericPromise2(ApplicationConf.gdl.adminFeature(id), { method: 'DELETE' })
        .then(thenFunctionDeleteBuilder('Fonctionnalitée supprimée avec succès', dispatch))
        .catch(catchFunctionBuilder('Erreur lors de la suppression d\'une fonctionnalitée', dispatch)),
}

export default GDLAction