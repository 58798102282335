import { createRouterReducer, createRouterMiddleware } from '@lagunovsky/redux-react-router'
import { createHashHistory } from 'history'
import { combineReducers } from 'redux'
import logger from 'redux-logger'
import ToastrReducer, { initialState as ToastrReducerStore } from 'toastr/reducers/ToastrReducer'
import WaitReducer, { initialState as WaitReducerStore } from 'wait/reducers/WaitReducer'
import HomeReducer, { initialState as HomeReducerStore } from 'home/reducers/HomeReducer'
import AdminReducer, { initialState as AdminReducerStore } from 'admin/reducers/AdminReducer'
import ServiceReducer, { initialState as ServiceReducerStore } from 'service/reducers/ServiceReducer'
import InstanceReducer, { initialState as InstanceReducerStore } from 'instance/reducers/InstanceReducer'
import TimeErrorReducer, { initialState as TimeErrorReducerStore } from 'timeError/reducers/TimeErrorReducer'
import GDLReducer, { initialState as GDLReducerStore } from 'gdl/reducers/GDLReducer'
import { configureStore } from '@reduxjs/toolkit'
import { RESET_ALL } from './StoreConstants'

export const history = createHashHistory()

const initialStore = {
    HomeReducer: HomeReducerStore,
    AdminReducer: AdminReducerStore,
    GDLReducer: GDLReducerStore,
    InstanceReducer: InstanceReducerStore,
    ServiceReducer: ServiceReducerStore,
    TimeErrorReducer: TimeErrorReducerStore,
    ToastrReducer: ToastrReducerStore,
    WaitReducer: WaitReducerStore,
}

const appReducers = combineReducers(
    {
        router: createRouterReducer(history),
        HomeReducer,
        AdminReducer,
        GDLReducer,
        InstanceReducer,
        ServiceReducer,
        TimeErrorReducer,
        ToastrReducer,
        WaitReducer,
    })

const RootReducer = (state, action) => {
    if (action.type === RESET_ALL) {
        return {
            ...state,
            ...initialStore,
        }
    }
    return appReducers(state, action)
}

const AppStore = configureStore({
    reducer: RootReducer,
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
        logger,
        createRouterMiddleware(history),
    ],
    devTools: 'development',
    preloadedState: initialStore,
})

export default AppStore
