import { isNil } from 'lodash'

const PERMISSIONS = {
    admin: {
        users: {
            view: true,
            create: true,
            update: true,
            delete: true,
        },
    },
    gdl: {
        instances: {
            view: true,
            create: true,
            update: true,
            delete: true,
        },
        settingLicenses: {
            view: true,
            create: true,
            update: true,
            delete: true,
        },
    },
    supervision: {
        instances: {
            view: true,
            create: true,
            update: true,
            delete: true,
        },
        services: {
            view: true,
            create: true,
            update: true,
            delete: true,
        },
        timeErrors: {
            view: true,
            create: true,
            update: true,
            delete: true,
        },
    },
    developer: {
        instances: {
            view: true,
        },
        services: {
            view: true,
            create: true,
            update: true,
            delete: true,
        },
        settingLicenses: {
            view: true,
        },
    },
}

const hasPermission = (user = {}, resource = '', action = '', data) => {
    if (isNil(user.habs)) return false
    return user.habs.some(role => {
        const permission = PERMISSIONS[role]?.[resource]?.[action] ?? false
        if (typeof permission === 'boolean') return permission
        return permission(user, data)
    })
}

export { hasPermission }

