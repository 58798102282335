/* eslint-disable react-hooks/exhaustive-deps */
import { HomeActionConstant } from 'home/reducers/HomeReducer'
import { isUndefined } from 'lodash'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const useActions = (callback, dependencies = []) => {
    const dispatch = useDispatch()

    useEffect(() => {
        const actions = callback()
        const filteredActions = actions.filter(a => a.displayed || isUndefined(a.displayed))
        dispatch(HomeActionConstant.actions(filteredActions))
    }, dependencies)

    useEffect(() => { // clean actions
        return () => dispatch(HomeActionConstant.actions([]))
    }, [])
}

export default useActions