import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    adminFeatures: [],
}

const store = createSlice({
    name: 'gdl',
    initialState,
    reducers: {
        adminFeatures: (state, action) => {
            state.adminFeatures = action.payload
        },
    },
})

export const GDLActionConstant = store.actions
export default store.reducer