import { Grid2 } from '@mui/material'
import GDLAction from 'gdl/actions/GDLAction'
import { groupBy, orderBy } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import FeatureExplorer from './AdminFeatureExplorer'
import useBoolean from 'utils/customHook/useBoolean'
import ConfirmModal from 'components/modal/ConfirmModal'
import DialogAdminFeature from './DialogAdminFeature'
import useTitles from 'utils/customHook/useTitles'
import { CardTable } from 'components/datatable/Table'
import useListIndexed from 'utils/customHook/useListIndexed'
import useActions from 'utils/customHook/useActions'
import { NEW } from 'components/action/ActionConstant'
import { hasPermission } from 'utils/HabilitationUtil'

const existInTree = (feature = {}, adminFeatures = []) => {
    return adminFeatures.some(af => af.id === feature.id || existInTree(feature, af.subFeatures))
}

const SettingLicenseApp = ({

}) => {
    const dispatch = useDispatch()
    const {
        user,
        adminFeatures,
    } = useSelector(store => ({
        user: store.AdminReducer.user,
        adminFeatures: store.GDLReducer.adminFeatures,
    }), shallowEqual)

    const indexedAdminFeatures = useListIndexed(adminFeatures, 'id')

    useTitles(() => [{
        label: 'Paramètrage licence',
        href: '/settingLicense',
    }], [])

    useEffect(() => {
        dispatch(GDLAction.fetchAdminFeatures())
    }, [])

    const [selectedAdminFeature, setSelectedAdminFeature] = useState({})
    const {
        value: isDialogOpen,
        setTrue: onOpenDialog,
        setFalse: onCloseDialog,
    } = useBoolean(false)
    const {
        value: isConfirmOpen,
        setTrue: onOpenConfirm,
        setFalse: onCloseConfirm,
    } = useBoolean(false)

    useActions(() => [{
        type: NEW,
        onClick: onOpenDialog,
        displayed: hasPermission(user, 'settingLicenses', 'create'),
    }], [])

    const formatAdminFeatures = useCallback((filteredAdminFeatures = [], groupedAdminFeatures = {}) => {
        return filteredAdminFeatures.map(({ id, code, name, pluggedInFrontend }) => {
            const subFeatures = formatAdminFeatures(groupedAdminFeatures[id], groupedAdminFeatures)
            return {
                id,
                label: `[${code}] ${name}`,
                pluggedInFrontend,
                subFeatures,
            }
        })
    }, [])

    const groupedAdminFeatures = groupBy(orderBy(adminFeatures, 'code'), s => s.parent ?? 'headTree')
    const formattedGroupedAdminFeatures = formatAdminFeatures(groupedAdminFeatures.headTree, groupedAdminFeatures)

    const adminFeaturesNotInTree = adminFeatures.filter(af => !existInTree(af, formattedGroupedAdminFeatures))

    const onCreate = id => {
        setSelectedAdminFeature({ parent: id })
        onOpenDialog()
    }
    const onUpdate = id => {
        setSelectedAdminFeature(adminFeatures.find(af => af.id === id))
        onOpenDialog()
    }
    const onDelete = id => {
        setSelectedAdminFeature({ id })
        onOpenConfirm()
    }

    return (
        <>
            <Grid2 container spacing={1} alignItems='flex-start' sx={{ padding: '5px 15%' }}>
                {formattedGroupedAdminFeatures.map(adminFeature => (
                    <Grid2 key={adminFeature.code} size={12}>
                        <FeatureExplorer
                            adminFeature={adminFeature}
                            actions={[
                                {
                                    icon: 'add_circle_outline',
                                    onClick: onCreate,
                                    displayed: hasPermission(user, 'settingLicenses', 'create'),
                                },
                                {
                                    icon: 'edit',
                                    onClick: onUpdate,
                                    displayed: hasPermission(user, 'settingLicenses', 'update'),
                                },
                                // {
                                //     icon: 'delete_outlined',
                                //     onClick: onDelete,
                                //     displayed: hasPermission(user, 'settingLicenses', 'delete'),
                                // },
                            ]}
                        />
                    </Grid2>
                ))}
                {adminFeaturesNotInTree.length > 0 && (
                    <Grid2 size={12}>
                        <CardTable
                            title={'Fonctionnalité non présente dans l\'arbre'}
                            headers={['id', 'code', 'description', { key: 'idParent', value: 'Id parent' }, { key: 'codeParent', value: 'Code parent' }]}
                            rows={adminFeaturesNotInTree.map(af => ({
                                id: af.id,
                                code: af.code,
                                description: af.description,
                                idParent: af.parent,
                                codeParent: indexedAdminFeatures[af.parent]?.code,
                            }))}

                            lineActions={[
                                {
                                    icon: 'edit',
                                    onClick: ({ id }) => {
                                        setSelectedAdminFeature(adminFeatures.find(af => af.id === id))
                                        onOpenDialog()
                                    },
                                    displayed: hasPermission(user, 'settingLicenses', 'update'),
                                },
                                {
                                    icon: 'delete',
                                    onClick: ({ id }) => {
                                        setSelectedAdminFeature({ id })
                                        onOpenConfirm()
                                    },
                                    displayed: hasPermission(user, 'settingLicenses', 'delete'),
                                },
                            ]}
                        />
                    </Grid2>
                )}
            </Grid2>
            <DialogAdminFeature
                isOpen={isDialogOpen}
                onClose={() => {
                    setSelectedAdminFeature()
                    onCloseDialog()
                }}
                onValidate={() => dispatch(GDLAction.fetchAdminFeatures())}
                adminFeature={selectedAdminFeature}
            />
            <ConfirmModal
                isOpen={isConfirmOpen}
                title='Êtes-vous sûr de vouloir supprimer cette fonctionnalité ?'
                onValidate={() => {
                    dispatch(GDLAction.deleteAdminFeatures(selectedAdminFeature.id)).then(() => {
                        dispatch(GDLAction.fetchAdminFeatures())
                        setSelectedAdminFeature()
                        onCloseConfirm()
                    })
                }}
                onClose={onCloseConfirm}
            />
        </>
    )
}

export default SettingLicenseApp