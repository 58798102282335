import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Card, CardContent, Grid2 } from '@mui/material'
import AuthAction from '../actions/AuthAction'
import { useParams } from 'react-router-dom'
import Checkbox from '../../components/form/Checkbox'
import useTitles from '../../utils/customHook/useTitles'
import useActions from '../../utils/customHook/useActions'
import { SAVE } from '../../components/action/ActionConstant'
import useFetch from 'utils/customHook/useFetch'
import ApplicationConf from 'conf/ApplicationConf'
import { SUCCEEDED } from 'store/StoreConstants'
import { isNil } from 'lodash'
import { hasPermission } from 'utils/HabilitationUtil'

const HABILITATION = {
    SUPERVISION: 'supervision',
    ADMIN: 'admin',
    GDL: 'gdl',
    DEVELOPER: 'developer',
}

const UserApp = ({

}) => {
    const dispatch = useDispatch()
    const { propsUser } = useSelector(store => ({ propsUser: store.AdminReducer.user }), shallowEqual)

    const { login } = useParams()

    const [user, setUser] = useState()

    const {
        data: fetchedUser,
        status,
    } = useFetch(ApplicationConf.auth.getUser(login))

    useEffect(() => {
        if (status !== SUCCEEDED) return
        setUser(fetchedUser)
    }, [fetchedUser, status])

    useTitles(() => [{
        label: 'Utilisateurs',
        href: '/user',
    }, {
        label: login,
        href: `/user/${login}`,
    }], [])

    useActions(() => [{
        type: SAVE,
        onClick: () => dispatch(AuthAction.updateUserHabs(login, user.habs)),
        displayed: hasPermission(propsUser, 'users', 'update'),
    }], [user])

    const changeHab = hab => {
        if (user.habs.includes(hab)) {
            setUser({ ...user, habs: user.habs.filter(h => h !== hab) })
        } else {
            setUser({ ...user, habs: [...user.habs, hab] })
        }
    }

    return !isNil(user) && (
        <Card>
            <CardContent>
                <Grid2 container spacing={1} alignItems={'flex-start'}>
                    <Grid2 size={3}>
                        <img
                            src={user.picture}
                            alt=''
                            width='50%'
                            style={ { borderRadius: '15%' } }
                        />
                    </Grid2>
                    <Grid2 container size={9}>
                        <Grid2 size={12}>
                            <b style={{ fontSize: 15 }}>{ user.login }</b>
                        </Grid2>
                        <Grid2 size={12}>
                            <b style={{ fontSize: 15 }}>{ user.email }</b>
                        </Grid2>
                        <Grid2 size={3}>
                            <Checkbox label='Admin' checked={user?.habs?.includes(HABILITATION.ADMIN)} onToggle={ () => changeHab(HABILITATION.ADMIN) } />
                        </Grid2>
                        <Grid2 size={3}>
                            <Checkbox label='Developpeur' checked={user?.habs?.includes(HABILITATION.DEVELOPER)} onToggle={ () => changeHab(HABILITATION.DEVELOPER) } />
                        </Grid2>
                        <Grid2 size={3}>
                            <Checkbox label='Supervision' checked={user?.habs?.includes(HABILITATION.SUPERVISION)} onToggle={ () => changeHab(HABILITATION.SUPERVISION) } />
                        </Grid2>
                        <Grid2 size={3}>
                            <Checkbox label='Licences' checked={user?.habs?.includes(HABILITATION.GDL)} onToggle={ () => changeHab(HABILITATION.GDL) } />
                        </Grid2>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

export default UserApp